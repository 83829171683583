<template>
  <div class="allmain">
    <div class="header">
      <div class="header_logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="header_center">
        <div style="color: #2558e4" @click="gohome">首页</div>
        <div @click="goproductIntroduction">产品介绍</div>
        <div @click="gocompanyprofile">公司简介</div>
        <!-- <div @click="gopayorder">立即购买</div> -->
      </div>
      <div class="header_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <img src="../../assets/banner.png" />
    </div>
    <div class="main_title">
      <div class="main_titleData">客群数字化赋能</div>
      <div class="main_cardList">
        <el-card
          class="main_card"
          v-for="(item, index) in cardData"
          :key="index"
        >
          <img :src="require('@/assets/' + item.img)" />
          <div>{{ item.name }}</div>
          <div>{{ item.value }}</div>
        </el-card>
      </div>
    </div>
    <div class="main_case">
      <div class="main_case_bg">
        <div class="main_titleData">成功案例</div>
        <div class="main_case_data">
          <div class="main_case_title">
            <div>成功案例————开福区友莲果坊</div>
            <div>7月采集数据，8月实施策略</div>
            <div @click="goDetail">案例详情></div>
          </div>
          <div>
            <img src="../../assets/case_2.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="main_title">
      <div class="main_titleData">潜客增量赋能</div>
      <div class="main_cardList">
        <el-card
          class="main_card"
          v-for="(item, index) in incrementCardData"
          :key="index"
        >
          <img :src="require('@/assets/' + item.img)" />
          <div>{{ item.name }}</div>
          <div>{{ item.value }}</div>
        </el-card>
      </div>
    </div>
    <div class="main_case">
      <div class="main_case_bg">
        <div class="main_titleData">典型应用场景</div>
        <div class="main_center">
          <div>
            <img src="../../assets/typicalScene_1.png" />
          </div>
          <div>
            <img src="../../assets/typicalScene_2.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="main_bottom">
      <div class="main_bottom_bg">
        <div class="main_bottom_apply">
          <div>
            <img src="../../assets/apply.png" />
          </div>
          <div>
            <span>填写入驻申请，专人联系为您服务！</span>
          </div>
          <div>
            <img src="../../assets/code.png" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_logo">
        <img src="../../assets/fqsj_2.png" />
      </div>
      <div class="footer_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat_on.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone_on.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023024436号</a>
      </div>
      <div>联系地址：开福区秀峰街道秀峰商贸城12栋2号101</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardData: [
        {
          name: "客群数字化采集",
          img: "card_1.png",
          value:
            "采集过店客群、进店客群的着装、特征、喜好等等数据，将客群进行分群。",
        },
        {
          name: "客群指标分析",
          img: "card_2.png",
          value: "针对客群分群的数据指标分析门店客群关系以及客群的趋势变化。",
        },
        {
          name: "业绩成交漏斗",
          img: "card_3.png",
          value:
            "分析门店的业绩结构，通过成交漏斗的关系将影响门店的关键点展示出来。",
        },
        {
          name: "门店客群对比",
          img: "card_4.png",
          value:
            "对门店的各个客群的数据指标进行比较，划分主力客群及潜力客群，发掘潜在顾客。",
        },
      ],
      incrementCardData: [
        {
          name: "客群预测",
          img: "increment_1.png",
          value:
            "根据以往的客群采集数据，分析客群构成以及变化趋势，对未来几天的过店客群进行预测。",
        },
        {
          name: "优化商品营销",
          img: "increment_2.png",
          value: "根据采集数据，分析主力客群，针对主力客群优化商品营销策略。",
        },
        {
          name: "发掘潜客增量",
          img: "increment_3.png",
          value:
            "分析门店的业绩结构，通过成交漏斗的关系将影响门店的关键点展示出来。",
        },
        {
          name: "增量品类异业连接",
          img: "increment_4.png",
          value:
            "对门店的各个客群的数据指标进行比较，划分主力客群及潜力客群，发掘潜在顾客。",
        },
      ],
    };
  },
  methods: {
    goDetail() {
      // 在 Vue 实例或组件中调用
      this.$router.push({ name: "caseDetail" });
    },
    gohome() {
      this.$router.push({ name: "home" });
    },
    goproductIntroduction() {
      this.$router.push({ name: "productIntroduction" });
    },
    gocompanyprofile() {
      this.$router.push({ name: "companyprofile" });
    },
    gopayorder() {
      this.$router.push({ name: "payOrder" });
    },
  },
};
</script>

<style scoped>
.allmain {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.header {
  height: 100px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.header_right {
  display: flex;
  align-items: center;
}
.header_right div {
  margin: 0 10px;
}
.header_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.header_center {
  width: 50%;
  display: flex;
}
.header_center div {
  margin: 0px 50px;
}
.main img {
  width: 100%;
}
.main_title {
  text-align: center;
  margin: 120px 15%;
}
.main_cardList {
  display: flex;
}
.main_card {
  width: 25%;
  padding: 70px 15px 20px 15px;
  margin: 10px;
}
.main_card div:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 20px;
}

.main_card div:nth-child(3) {
  font-size: 14px;
  color: #777777;
  text-align: left;
}
.main_titleData {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 80px;
}
.main_case {
  width: 100%;
  background: url("../../assets/case_1.png") no-repeat;
  background-size: 100% 100%;
}
.main_case_bg {
  text-align: center;
  padding: 50px 15%;
}
.main_case_data {
  width: 100%;
  background-color: white;
  position: relative;
}
.main_case_data img {
  width: 100%;
  height: 100%;
}
.main_case_title {
  width: 100%;
  position: absolute;
  top: 60px;
  display: flex;
}
.main_case_title div:nth-child(1) {
  margin-left: 50px;
  color: #2963ff;
  font-size: 24px;
  font-weight: 700;
}
.main_case_title div:nth-child(2) {
  color: #111111;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 14px;
}
.main_case_title div:nth-child(3) {
  color: #777777;
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 18px;
}
.main_bottom {
  width: 100%;
  background: url("../../assets/FQSJ_logo.png") no-repeat;
  background-size: 100% 100%;
}
.main_bottom_bg {
  text-align: center;
  height: 320px;
}
.main_bottom_apply div:nth-child(1) {
  padding: 80px 0px 20px 0px;
}
.main_bottom_apply div:nth-child(2) {
  color: #777777;
  font-size: 14px;
  padding-bottom: 20px;
}
.main_bottom_apply .el-button {
  width: 160px;
  height: 42px;
  color: white;
  background-color: #2558e4;
  border-radius: 10px;
}
.footer {
  background-color: #111111;
  color: white;
  height: 100px;
  padding: 0px 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_right {
  display: flex;
  align-items: center;
}
.footer_right div {
  margin: 0 10px;
}
.footer_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.record {
  height: 80px;
  color: white;
  padding: 0 10%;
  background-color: #3d3d3d;
  width: 80%;
  line-height: 40px;
}
.record div {
  margin: 0px 10px;
}
.record a {
  color: white;
  text-decoration: none;
}
.main_center {
  display: flex;
  justify-content: space-between;
}
.main_center div {
  width: 50%;
}
.main_center img {
  width: 100%;
}
</style>
